import { Route, BrowserRouter, Routes, Navigate } from 'react-router-dom';
import BaseLayout from './Layout/BaseLayout/BaseLayout';
import './App.less';

const App = () => (
  <BrowserRouter>
    <Routes>
      <Route path="/" element={<Navigate replace to="/home" />} />
      <Route path="/home" element={<BaseLayout />} />
    </Routes>
  </BrowserRouter>
);
export default App;
